import { ref, watch } from '@vue/composition-api';
import store from '@/store';

import toast from '@/utils/toast';
import { isEnableBed } from '@/auth/utils';

export default function useListTenantModal(props) {
  // Use toast
  const toastification = toast();

  // Table Handlers
  const columns = [
    {
      label: 'Mã',
      field: 'code',
    },
    {
      label: 'Họ tên',
      field: 'name',
    },
    {
      label: 'SĐT',
      field: 'phone',
    },
    {
      label: 'CMND/CCCD',
      field: 'idNumber',
    },
  ];
  const rows = ref([]);
  // filter
  const apartment = ref(null);
  const room = ref(null);
  const bed = ref(null);
  const status = ref(null);
  const foreign = ref(null);
  const individual = ref(null);
  // current selected rows
  const selectedRows = ref([]);
  const searchTerm = ref('');
  // ssr
  const isLoading = ref(false);
  const totalRecords = ref(0);
  const serverParams = ref({
    searchTerm: '',
    filter: {},
    sort: {},
    page: 1,
    perPage: 20,
  });

  //   API Call
  const fetchTenants = () => {
    isLoading.value = true;
    store
      .dispatch('contract/fetchTenants', serverParams.value)
      .then(response => {
        const { total, items } = response.data;
        totalRecords.value = total;
        const data = [...items];
        if (props.tenantsSelected && props.tenantsSelected.length) {
          const listIdTenantSelected = props.tenantsSelected.map(tenant => tenant.id);
          const dataRes = data.filter(res => !listIdTenantSelected.includes(res.id));
          rows.value = [...dataRes];
        } else rows.value = [...items];
      })
      .catch(error => {
        toastification.showToastError(error);
      });
  };

  //   Methods
  const updateParams = newProps => {
    serverParams.value = { ...serverParams.value, ...newProps };
    fetchTenants();
  };

  const onPageChange = params => {
    updateParams({ page: params.currentPage });
  };

  const onPerPageChange = params => {
    updateParams({ perPage: params.currentPerPage });
  };

  const onSortChange = params => {
    updateParams({
      sort: {
        type: params[0].type,
        field: params[0].field,
      },
    });
  };

  const onColumnFilter = params => {
    updateParams(params);
  };

  const selectionChanged = params => {
    selectedRows.value = params.selectedRows;
  };

  const resetValue = () => {
    rows.value = [];
    // filter
    apartment.value = null;
    room.value = null;
    bed.value = null;
    status.value = null;
    foreign.value = null;
    individual.value = null;
    // current selected rows
    searchTerm.value = '';
    selectedRows.value = [];
    // ssr
    isLoading.value = false;
    totalRecords.value = 0;
    serverParams.value = {
      searchTerm: '',
      filter: {},
      sort: {},
      page: 1,
      perPage: 20,
    };
  };

  //   Watch

  watch(apartment, val => {
    const { filter } = serverParams.value;
    if (val) {
      filter.apartmentId = val.id;
    } else {
      delete filter.apartmentId;
    }
    updateParams({ filter });
  });
  watch(room, val => {
    const { filter } = serverParams.value;
    if (val) {
      filter.roomId = val.id;
    } else {
      delete filter.roomId;
    }
    updateParams({ filter });
  });
  watch(status, val => {
    const { filter } = serverParams.value;
    if (val) {
      filter.status = val.value;
    } else {
      delete filter.status;
    }
    updateParams({ filter });
  });
  watch(foreign, val => {
    const { filter } = serverParams.value;
    if (val) {
      filter.foreign = val.value;
    } else {
      delete filter.foreign;
    }
    updateParams({ filter });
  });
  watch(individual, val => {
    const { filter } = serverParams.value;
    if (val) {
      filter.individual = val.value;
    } else {
      delete filter.individual;
    }
    updateParams({ filter });
  });
  watch(searchTerm, val => {
    updateParams({ searchTerm: val });
  });

  const resolveColWidthIfDisableBed = isBed => {
    if (isEnableBed()) {
      return 3;
    }
    if (isBed) {
      return 0;
    }
    return 4;
  };

  return {
    columns,
    rows,
    apartment,
    room,
    bed,
    status,
    foreign,
    individual,
    selectedRows,
    searchTerm,
    isLoading,
    totalRecords,
    serverParams,

    fetchTenants,

    updateParams,
    onPageChange,
    onPerPageChange,
    onSortChange,
    onColumnFilter,
    resetValue,
    selectionChanged,
    resolveColWidthIfDisableBed,
  };
}
