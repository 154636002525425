<template>
  <b-modal
    :id="`modal-meter-${id}`"
    ref="refModal"
    title="Công tơ"
    size="lg"
    :ok-title="isSubmitting ? 'Đang lưu' : 'Lưu'"
    :ok-disabled="isSubmitting"
    cancel-title="Huỷ"
    cancel-variant="outline-secondary"
    :hide-header-close="true"
    no-close-on-backdrop
    @show="resetModal"
    @hidden="resetModal"
    @ok="onSubmit"
  >
    <!-- Body -->
    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <b-form
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >
        <b-row>
          <b-col md="6">
            <validation-provider
              #default="validationContext"
              name="name"
              rules="required"
            >
              <b-form-group
                label="Tên công tơ"
              >
                <template
                  v-slot:label
                >
                  Tên công tơ
                  <span
                    class="text-danger"
                  >
                    (*)
                  </span>
                </template>
                <b-input-group>
                  <b-input-group-prepend is-text>
                    <feather-icon icon="ZapIcon" />
                  </b-input-group-prepend>
                  <b-form-input
                    id="name"
                    v-model="itemLocal.name"
                    placeholder="CTĐ-P.101"
                    trim
                    :state="getValidationState(validationContext)"
                  />
                </b-input-group>
                <small class="text-danger">{{
                  validationContext.errors[0]
                }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col md="6">
            <validation-provider
              #default="validationContext"
              name="type"
              rules="required"
            >
              <select-meter-type
                v-model="itemLocal.type"
                required
                :enable="!itemLocal.default"
                :default-value="itemLocal.type"
                label="Loại công tơ"
                :state="getValidationState(validationContext)"
                :error="validationContext.errors[0]"
              />
            </validation-provider>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6">
            <validation-provider
              #default="validationContext"
              name="apartment"
              rules="required"
            >
              <select-apartment
                v-model="itemLocal.apartment"
                :filter-active="true"
                required
                :enable="!itemLocal.default"
                :enable-add="true"
                :default-value="itemLocal.apartment"
                label="Tòa nhà"
                :state="getValidationState(validationContext)"
                :error="validationContext.errors[0]"
              />
            </validation-provider>
          </b-col>
          <b-col md="6">
            <validation-provider
              #default="validationContext"
              name="room"
              rules="required"
            >
              <select-room
                v-model="itemLocal.room"
                :filter-active="true"
                required
                :enable="!itemLocal.default"
                :enable-add="true"
                :default-value="itemLocal.room"
                label="Phòng"
                :apartment="itemLocal.apartment"
                :state="getValidationState(validationContext)"
                :error="validationContext.errors[0]"
              />
            </validation-provider>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <number-input
              v-model="itemLocal.firstIndex"
              required
              label="Chỉ số đầu"
              :default-value="itemLocal.firstIndex"
            />
          </b-col>

        </b-row>
        <b-row>
          <b-col cols="12">
            <b-form-group label="Mô tả">
              <b-form-textarea
                id="description"
                v-model="itemLocal.description"
                placeholder="Mô tả"
                rows="3"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import {
  BModal,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BForm,
  BFormTextarea,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import SelectMeterType from "@/views/components/SelectMeterType.vue";
import SelectApartment from "@/views/components/SelectApartment.vue";
import NumberInput from "@/views/components/NumberInput.vue";
import SelectRoom from "@/views/components/SelectRoom.vue";
import useMeterModal from "./useMeterModal";

export default {
  components: {
    BModal,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BForm,
    BFormTextarea,
    ValidationProvider,
    ValidationObserver,

    SelectMeterType,
    SelectApartment,
    SelectRoom,
    NumberInput,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    id: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      required,
    };
  },
  setup(props, { emit }) {
    const {
      refFormObserver, getValidationState, resetForm, clearForm,
    } = formValidation();

    const {
      refModal,
      itemLocal,
      isSubmitting,
      resetItemLocal,
      resetModal,

      onSubmit,
    } = useMeterModal(props, emit, refFormObserver);

    return {
      refModal,
      itemLocal,
      isSubmitting,
      resetItemLocal,
      resetModal,
      onSubmit,

      // Form Validation
      resetForm,
      clearForm,
      refFormObserver,
      getValidationState,
    };
  },
};
</script>
