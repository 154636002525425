<template>
  <b-modal
    id="modal-reservation-detail"
    ref="refModalReservationDetail"
    title="Thông tin đặt cọc"
    size="lg"
    scrollable
    :hide-footer="true"
    no-close-on-backdrop
    @show="onOpen"
    @hidden="resetModal"
  >

    <div class="d-flex justify-content-center mb-1">
      <b-spinner
        v-if="isLoading"
        class="text-center"
        variant="secondary"
      />
    </div>

    <div v-if="itemLocal && !isLoading">
      <b-list-group class="">

        <b-list-group-item v-if="itemLocal.apartment">
          <b-row>
            <b-col class="font-weight-bolder">
              Tên tòa nhà:
            </b-col>
            <b-col class="">
              {{ itemLocal.apartment.name }}
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item v-if="itemLocal.room">
          <b-row>
            <b-col class="font-weight-bolder">
              Tên phòng:
            </b-col>
            <b-col class="">
              {{ itemLocal.room.name }}
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item v-if="itemLocal.bed">
          <b-row>
            <b-col class="font-weight-bolder">
              Tên giường:
            </b-col>
            <b-col class="">
              {{ itemLocal.bed.name }}
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item v-if="itemLocal.tenant">
          <b-row>
            <b-col class="font-weight-bolder">
              Khách cọc:
            </b-col>
            <b-col class="">
              {{ itemLocal.tenant.name }}
            </b-col>
          </b-row>
        </b-list-group-item>

        <b-list-group-item>
          <b-row>
            <b-col class="font-weight-bolder">
              Giá thuê:
            </b-col>
            <b-col class="">
              {{ Number(itemLocal.priceForRent).toLocaleString() }}
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item>
          <b-row>
            <b-col class="font-weight-bolder">
              Cọc trước:
            </b-col>
            <b-col class="">
              {{ Number(itemLocal.deposit).toLocaleString() }}
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item>
          <b-row>
            <b-col class="font-weight-bolder">
              Ngày cọc:
            </b-col>
            <b-col class="">
              {{ parseDateToString(itemLocal.depositDate) }}
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item>
          <b-row>
            <b-col class="font-weight-bolder">
              Ngày chuyển vào:
            </b-col>
            <b-col class="">
              {{ parseDateToString(itemLocal.checkinDate) }}
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item v-if="itemLocal.cashbook">
          <b-row>
            <b-col class="font-weight-bolder">
              Sổ quỹ:
            </b-col>
            <b-col class="">
              {{ itemLocal.cashbook.name }}
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item v-if="itemLocal.note">
          <b-row>
            <b-col class="font-weight-bolder">
              Ghi chú:
            </b-col>
            <b-col class="">
              {{ itemLocal.note }}
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item v-if="itemLocal.status">
          <b-row>
            <b-col class="font-weight-bolder">
              Trạng thái:
            </b-col>
            <b-col class="">
              <b-badge
                pill
                :variant="itemLocal.status.variant"
              >{{ itemLocal.status.name }}</b-badge>
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item v-if="itemLocal.refund && parseInt(itemLocal.refund, 10) > 0">
          <b-row>
            <b-col class="font-weight-bolder">
              Số tiền hoàn cọc:
            </b-col>
            <b-col class="">
              {{ Number(itemLocal.refund).toLocaleString() }}
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item>
          <b-row>
            <b-col class="font-weight-bolder">
              Mẫu biểu:
            </b-col>
            <b-col class="">
              <b-link
                v-b-modal.modal-preview-reservation
                variant="flat-info"
              >
                <feather-icon
                  icon="FileTextIcon"
                  class="mr-50 font-medium-3"
                />
                <span class="">Xem hợp đồng cọc</span>
              </b-link>

            </b-col>
          </b-row>
        </b-list-group-item>

      </b-list-group>

      <reservation-preview-modal :item="itemLocal" />

    </div>
    <!-- Body -->

  </b-modal>
</template>

<script>
import {
  BModal,
  BSpinner,
  BCol,
  BRow,
  BListGroup,
  BListGroupItem,
  BBadge,
  BLink,
} from 'bootstrap-vue';
import { required } from '@validations';
import formValidation from '@core/comp-functions/forms/form-validation';
import { parseDateToString } from '@/auth/utils';
import useReservationDetailModal from './useReservationDetailModal';
import ReservationPreviewModal from '../preview/ReservationPreviewModal.vue';

export default {
  components: {
    BModal,
    BSpinner,
    BCol,
    BRow,
    BListGroup,
    BListGroupItem,
    BBadge,
    BLink,
    ReservationPreviewModal,
  },
  props: {
    reservationId: {
      type: Number,
      default: 0,
      required: true,
    },
  },
  data() {
    return {
      required,
    };
  },
  setup(props, { emit }) {
    const {
      refFormObserver, getValidationState, resetForm, clearForm,
    } = formValidation();

    const {
      refModalDeleteCashbook,
      itemLocal,
      resetItemLocal,
      resetModal,
      onOpen,
      isLoading,
    } = useReservationDetailModal(props, emit, refFormObserver);

    return {
      refModalDeleteCashbook,
      itemLocal,
      resetItemLocal,
      resetModal,
      // Form Validation
      resetForm,
      clearForm,
      refFormObserver,
      getValidationState,
      onOpen,
      isLoading,
      parseDateToString,
    };
  },
};
</script>

<style lang="scss">
  @import "@core/scss/vue/libs/vue-good-table.scss";
  .vgt-wrap {
    .vgt-table {
      thead {
        th {
          font-size: 1rem !important;
          font-weight: 500;
        }
      }
      td {
        font-size: 1rem !important;
        font-weight: 500;
      }
    }
  }
  </style>
