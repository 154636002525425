<template>
  <div>
    <small class="text-danger pt-2">{{ error }}</small>
    <vue-good-table
      class="pt-2"
      :columns="columns"
      :rows="tenants"
      :select-options="{
        enabled: false,
        selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true, // disable the select info panel on top
        selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      }"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >
        <!-- Column: isRepresent -->
        <span v-if="props.column.field === 'isRepresent'">
          <b-form-checkbox
            v-model="props.row.isRepresent"
            name="check-button"
            switch
            inline
            @change="onChangeRepresent( $event, props.row)"
          />
        </span>

        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <table-actions
            name="Khách hàng"
            :hide-edit="true"
            @on-delete="onDelete(props.row)"
          />
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
      <div slot="emptystate">
        <div class="text-center text-muted">
          Không có bản ghi nào!
        </div>
      </div>
    </vue-good-table>

  </div>

</template>

<script>
import {
  BFormCheckbox,
} from 'bootstrap-vue';
import TableActions from '@/views/components/TableActions.vue';
import { VueGoodTable } from 'vue-good-table';

export default {
  components: {
    BFormCheckbox,

    VueGoodTable,
    TableActions,
  },
  props: {
    rows: {
      type: Array,
      default: () => [],
    },
    error: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      tenants: this.rows,
    };
  },

  watch: {
    rows(val) {
      this.tenants = val;
    },
  },

  methods: {
    onChangeRepresent(value, row) {
      if (this.tenants && this.tenants.length === 1) {
        this.tenants = this.tenants.map(tenant => ({ ...tenant, isRepresent: true }));
      } else {
        const tenantsChanged = this.tenants.map(tenant => (tenant.tenant.id === row.tenant.id
          ? { ...tenant, isRepresent: value }
          : { ...tenant, isRepresent: false }));
        this.tenants = tenantsChanged;
      }

      this.onChange();
    },
    onDelete(row) {
      const remainTenants = this.tenants.filter(tenant => tenant.tenant.id !== row.tenant.id);
      if (remainTenants.filter(obj => obj.isRepresent).length === 0 && remainTenants.length > 0) {
        remainTenants[0].isRepresent = true;
      }
      this.tenants = remainTenants;
      this.onChange();
    },
    onChange() {
      this.$emit('input', this.tenants);
    },
  },
  setup() {
    // Table Handlers
    const columns = [
      {
        label: 'Tên cư dân',
        field: 'tenant.name',
        sortable: false,
      },
      {
        label: 'Số điện thoại',
        field: 'tenant.phone',
        sortable: false,
        width: "140px",
      },
      {
        label: 'Đại diện',
        field: 'isRepresent',
        sortable: false,
        width: "100px",
      },
      {
        label: 'Thao tác',
        field: 'action',
        tdClass: 'text-center',
        sortable: false,
        width: "100px",
      },
    ];

    return {
      columns,
    };
  },
};
</script>
