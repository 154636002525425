<!-- eslint-disable no-restricted-syntax -->
<template>
  <vue-good-table
    class="mt-2"
    :columns="columns"
    :rows="assets"
    :select-options="{
      enabled: false,
      selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
      selectionInfoClass: 'custom-class',
      selectionText: 'rows selected',
      clearSelectionText: 'clear',
      disableSelectInfo: true, // disable the select info panel on top
      selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
    }"
  >
    <template slot="loadingContent">
      <b-spinner
        label="Loading"
        type="grow"
      />
    </template>
    <template
      slot="table-row"
      slot-scope="props"
    >
      <!-- Column: isRepresent -->
      <span v-if="props.column.field === 'isNormal'">
        <div class="demo-inline-spacing">
          <b-form-radio
            v-model="props.row.isNormal"
            :name="`isNormal-${props.row.originalIndex}`"
            :value="true"
            class="custom-control-primary"
            @input="changeCell(props.row[props.column.field], props.column.field, props.row.originalIndex)"
          >
            Hoạt động
          </b-form-radio>

          <b-form-radio
            v-model="props.row.isNormal"
            :name="`isNormal-${props.row.originalIndex}`"
            :value="false"
            class="custom-control-primary"
            @input="changeCell(props.row[props.column.field], props.column.field, props.row.originalIndex)"
          >
            Không hoạt động
          </b-form-radio>
        </div>
      </span>

      <!-- Column: Image -->
      <span v-else-if="props.column.field === 'note'">
        <b-form-input
          :id="`note-${props.row.originalIndex}`"
          v-model="props.row.note"
          placeholder=""
          trim
          @input="changeCell(props.row[props.column.field], props.column.field, props.row.originalIndex)"
        />

      </span>

      <!-- Column: Image -->
      <span v-else-if="props.column.field === 'imageFile'">
        <image-view
          :id="`image-file-${props.row.originalIndex}`"
          v-model="props.row.imageFile"
          :src="props.row.image"
          :instruction="null"
          @input="changeCell(props.row[props.column.field], props.column.field, props.row.originalIndex)"
          @onSelectFile="(base64String) => onSelectFile(base64String, props.row.originalIndex)"
        />

      </span>

      <!-- Column: Common -->
      <span v-else>
        {{ props.formattedRow[props.column.field] }}
      </span>
    </template>
    <div slot="emptystate">
      <div class="text-center text-muted">
        Không có bản ghi nào!
      </div>
    </div>
  </vue-good-table>
</template>

<script>
import {
  BSpinner,
  BFormInput,
  BFormRadio,
} from 'bootstrap-vue';
import { VueGoodTable } from 'vue-good-table';
import useJwt from '@/auth/jwt/useJwt';
import ImageView from '@/views/components/ImageView.vue';

export default {
  components: {
    BSpinner,
    BFormInput,
    BFormRadio,
    VueGoodTable,
    ImageView,
  },
  props: {
    contractId: {
      type: Number,
      default: null,
    },
    apartment: {
      type: Object,
      default: null,
    },
    room: {
      type: Object,
      default: null,
    },
    data: {
      type: Array,
      default: () => [],
    },
    additionAssets: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      assets: this.data,
    };
  },
  watch: {
    apartment(newVal, oldVal) {
      if (oldVal !== newVal) {
        this.fetchData();
      }
    },
    room(newVal, oldVal) {
      if (oldVal !== newVal) {
        this.fetchData();
      }
    },
    additionAssets(newVal) {
      this.onAddNewAssets(newVal);
    },

  },
  created() {
    if (this.data.length === 0) {
      this.fetchData();
    }
  },
  methods: {
    changeCell(changedData, column, row) {
      this.assets[row][column] = changedData;
      this.onChange();
    },
    onChange() {
      this.$emit('input', this.assets);
    },
    onAddNewAssets(values) {
      // eslint-disable-next-line no-restricted-syntax
      for (const eachValue of values) {
        if (this.assets.filter(object => object.asset.id === eachValue.id).length === 0) {
          this.assets.push({
            asset: eachValue,
            note: '',
            isLessorResponsibility: true,
            isNormal: true,
            imageFile: null,
            base64String: null,
          });
        }
      }
      this.onChange();
    },
    fetchData() {
      if (!this.apartment || !this.room) {
        this.assets = [];
        return;
      }
      const filter = { };
      if (this.apartment && this.apartment.id > 0) {
        filter.apartmentId = this.apartment.id;
      }
      if (this.room && this.room.id > 0) {
        filter.roomId = this.room.id;
      }
      if (this.contractId && this.contractId > 0) {
        useJwt.getContractHandover(this.contractId).then(response => {
          this.assets = response.data;
          this.onChange();
        });
      } else {
        useJwt.getAssets({ filter }).then(response => {
          this.assets = response.data.items.map(object => ({
            asset: object,
            note: '',
            isLessorResponsibility: true,
            isNormal: true,
            imageFile: null,
            base64String: null,
          }));
          this.onChange();
        });
      }
    },
    onDeleteAttachment() {},
    onSelectFile(value, index) {
      this.assets[index].base64String = value;
      this.onChange();
    },
  },
  setup() {
    // Table Handlers
    const rows = [];
    const columns = [
      {
        label: 'Tài sản',
        field: 'asset.name',
        sortable: false,
      },
      {
        label: 'Tình trạng hoạt động',
        field: 'isNormal',
        sortable: true,
        width: '200px',
      },
      {
        label: 'Ghi chú',
        field: 'note',
        sortable: false,
        width: '200px',
      },
      {
        label: 'Hình ảnh',
        field: 'imageFile',
        sortable: false,
        width: '150px',
      },
    ];

    return {
      rows,
      columns,
    };
  },
};
</script>
