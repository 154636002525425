<template>
  <b-form-group
    :label="label"
  >
    <template
      v-if="label"
      v-slot:label
    >
      {{ label }}
      <span
        v-if="required"
        class="text-danger"
      >
        (*)
      </span>
    </template>
    <v-select
      id="status"
      v-model="status"
      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
      label="name"
      :options="options"
      placeholder="Trạng thái"
      @input="valueChanged"
    >
      <!-- eslint-disable-next-line vue/no-unused-vars  -->
      <template #no-options="{ search, searching, loading }">
        Không có dữ liệu
      </template></v-select>
  </b-form-group>
</template>

<script>
import { BFormGroup } from 'bootstrap-vue';
import vSelect from 'vue-select';

export default {
  components: {
    BFormGroup,
    vSelect,
  },
  props: {
    label: {
      type: String,
      default: null,
    },
    defaultValue: {
      type: Object,
      default: null,
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      status: this.defaultValue,
      options: [
        { name: 'Chờ ký HĐ', id: '1', value: '1' },
        { name: 'Đã ký HĐ', id: '2', value: '2' },
        { name: 'Bỏ cọc', id: '3', value: '3' },
      ],
    };
  },
  methods: {
    valueChanged() {
      this.$emit('input', this.status);
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@/assets/scss/vue-select.scss';
</style>
