import {
  ref, watch, computed,
} from '@vue/composition-api';
import store from '@/store';
import toast from '@/utils/toast';
import reservationStoreModule from '../reservationStoreModule';

export default function useReservationCancelModal(props, emit, refFormObserver) {
  const STORE_MODULE_NAME = 'reservation';

  // Register module
  if (!store.hasModule(STORE_MODULE_NAME)) {
    store.registerModule(STORE_MODULE_NAME, reservationStoreModule);
  }

  const toastification = toast();
  const refModalCancel = ref(null);
  const refForm = ref(refFormObserver);
  const itemLocal = ref(JSON.parse(JSON.stringify(props.item)));
  const resetItemLocal = () => {
    itemLocal.value = JSON.parse(JSON.stringify(props.item));
  };
  const isSubmitting = ref(false);

  const resetModal = () => {
    resetItemLocal();
    isSubmitting.value = false;
  };

  const room = computed(() => itemLocal.value.room);
  const bed = computed(() => itemLocal.value.room);

  const onSubmit = async bvModalEvt => {
    bvModalEvt.preventDefault();

    const success = await refForm.value.validate();
    if (!success) {
      return;
    }

    const data = {
      id: itemLocal.value.id,
      refund: itemLocal.value.refund,
      reason: itemLocal.value.refundReason,
    };

    isSubmitting.value = true;

    store
      .dispatch('reservation/terminateReservation', data)
      .then(response => { emit('on-item-updated', response.data); })
      .then(() => {
        refModalCancel.value.toggle('#toggle-btn');
      })
      .then(() => {
        emit('refetch-data');
      })
      .then(() => {
        toastification.showToastUpdateSuccess();
        isSubmitting.value = false;
      })
      .catch(error => {
        toastification.showToastError(error, refForm.value);
        isSubmitting.value = false;
      });
  };

  watch(room, val => {
    if (val) {
      itemLocal.value.priceForRent = val.price;
      itemLocal.value.deposit = val.deposit;
    }
  });
  watch(bed, val => {
    if (val) {
      itemLocal.value.priceForRent = val.price;
      itemLocal.value.deposit = val.deposit;
    } else if (room) {
      itemLocal.value.priceForRent = room.price;
      itemLocal.value.deposit = room.deposit;
    }
  });

  return {
    refModalCancel,
    refForm,
    itemLocal,
    isSubmitting,
    resetItemLocal,
    resetModal,
    onSubmit,
  };
}
