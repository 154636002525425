<template>
  <div>

    <b-modal
      id="modal-contract"
      ref="refModal"
      title="Hợp đồng"
      size="lg"
      :ok-title="isSubmitting ? 'Đang lưu' : 'Lưu'"
      :ok-disabled="isSubmitting"
      cancel-title="Huỷ"
      cancel-variant="outline-secondary"
      :hide-header-close="true"
      no-close-on-esc
      scrollable
      no-close-on-backdrop
      @show="resetModal"
      @hidden="resetModal"
      @ok="onSubmit"
    >
      <!-- Body -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <b-form
          @submit.prevent="handleSubmit(onCheckSubmit)"
          @reset.prevent="resetForm"
        >
          <b-row>
            <b-col
              cols="12"
              class="my-1"
            >
              <div class="d-flex justify-space-between">
                <div class="d-flex justify-space-between font-small-4 font-weight-bolder text-uppercase text-primary">
                  1. Thông tin chung
                </div>
              </div>
            </b-col>
          </b-row>

          <b-row>
            <b-col :md="resolveColWidthIfDisableBed()">
              <validation-provider
                #default="validationContext"
                name="apartment"
                rules="required"
              >
                <select-apartment
                  v-model="itemLocal.apartment"
                  required
                  :filter-active="true"
                  :default-value="itemLocal.apartment"
                  label="Tòa nhà"
                  :enable="itemLocal.id <= 0 && !disablePropertySelect"
                  :state="getValidationState(validationContext)"
                  :error="validationContext.errors[0]"
                />
              </validation-provider>
            </b-col>
            <b-col :md="resolveColWidthIfDisableBed()">
              <validation-provider
                #default="validationContext"
                name="room"
                rules="required"
              >
                <select-room
                  v-model="itemLocal.room"
                  :show-status="true"
                  :filter-active="true"
                  required
                  :apartment="itemLocal.apartment"
                  :default-value="itemLocal.room"
                  :enable="itemLocal.id <= 0 && !disablePropertySelect"
                  label="Phòng"
                  :state="getValidationState(validationContext)"
                  :error="validationContext.errors[0]"
                />
              </validation-provider>
            </b-col>
            <b-col
              v-if="resolveColWidthIfDisableBed(true) > 0"
              :md="resolveColWidthIfDisableBed()"
            >
              <validation-provider
                #default="validationContext"
                name="Giường"
                :rules="itemLocal.room && itemLocal.room.numberActiveBeds > 0 ? 'required' : ''"
              >
                <select-bed
                  v-model="itemLocal.bed"
                  :room="itemLocal.room"
                  :filter-active="true"
                  :default-value="itemLocal.bed"
                  label="Giường"
                  :enable="itemLocal.id <= 0 && itemLocal.room && itemLocal.room.numberActiveBeds > 0 && !disablePropertySelect"
                  :state="getValidationState(validationContext)"
                  :error="validationContext.errors[0]"
                />
              </validation-provider>
            </b-col>
          </b-row>
          <b-row>
            <b-col :md="isAllowContractPaymentDayConfig() ? 3 : 4">
              <validation-provider
                #default="validationContext"
                name="signDate"
                rules="required"
              >
                <select-date
                  v-model="itemLocal.signDate"
                  required
                  :default-value="itemLocal.signDate"
                  label="Ngày ký"
                  :state="getValidationState(validationContext)"
                  :error="validationContext.errors[0]"
                />
              </validation-provider>
            </b-col>
            <b-col :md="isAllowContractPaymentDayConfig() ? 3 : 4">
              <validation-provider
                #default="validationContext"
                name="startDate"
                rules="required|dateStartInvalid:@endDate"
              >
                <select-date
                  v-model="itemLocal.startDate"
                  required
                  :default-value="itemLocal.startDate"
                  label="Ngày bắt đầu"
                  :state="getValidationState(validationContext)"
                  :error="validationContext.errors[0]"
                  @input="onStartDateChange"
                />
              </validation-provider>
            </b-col>
            <b-col :md="isAllowContractPaymentDayConfig() ? 3 : 4">
              <validation-provider
                #default="validationContext"
                name="endDate"
                rules="required|dateEndInvalid:@startDate"
              >
                <select-date
                  v-model="itemLocal.endDate"
                  required
                  :default-value="itemLocal.endDate"
                  label="Hạn hợp đồng"
                  :state="getValidationState(validationContext)"
                  :error="validationContext.errors[0]"
                />
              </validation-provider>
            </b-col>
            <b-col
              v-if="isAllowContractPaymentDayConfig()"
              :md="isAllowContractPaymentDayConfig() ? 3 : 4"
            >
              <validation-provider
                #default="validationContext"
                name="Ngày thanh toán"
                rules="required|minValue:1|maxValue:31"
              >
                <number-input
                  v-model="itemLocal.paymentDay"
                  required
                  :default-value="itemLocal.paymentDay"
                  label="Ngày thanh toán"
                  placeholder="15"
                  :state="getValidationState(validationContext)"
                  :error="validationContext.errors[0]"
                />
              </validation-provider>

            </b-col>
          </b-row>

          <b-row>
            <b-col cols="12">
              <b-form-group label="Ghi chú">
                <b-form-textarea
                  id="note"
                  v-model="itemLocal.note"
                  placeholder="Ghi chú"
                  rows="3"
                />
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="12">
              <validation-provider
                #default="validationContext"
                name="Mẫu hợp đồng thuê"
                rules=""
              >
                <select-contract-template
                  v-model="itemLocal.contractTemplate"
                  required
                  :default-value="itemLocal.contractTemplate"
                  label="Mẫu hợp đồng thuê"
                  :state="getValidationState(validationContext)"
                  :error="validationContext.errors[0]"
                />
              </validation-provider>
            </b-col>
            <b-col cols="12">
              <validation-provider
                #default="validationContext"
                name="Mẫu in hóa đơn"
                rules=""
              >
                <select-invoice-template
                  v-model="itemLocal.invoiceTemplate"
                  :default-value="itemLocal.invoiceTemplate"
                  label="Mẫu in hóa đơn"
                  :state="getValidationState(validationContext)"
                  :error="validationContext.errors[0]"
                />
              </validation-provider>
            </b-col>
          </b-row>
          <hr class="mt-4">
          <b-row>

            <b-col
              cols="12"
              class="my-1"
            >
              <div class="d-flex justify-space-between">
                <div class="d-flex justify-space-between font-small-4 font-weight-bolder text-uppercase text-primary">
                  2. Khách hàng
                </div>
                <b-button
                  v-b-modal.modal-list-tenant
                  variant="primary"
                  class="btn-icon ml-auto"
                  size="sm"
                  :disabled="itemLocal.tenants.length === 1 && !!itemLocal.bed"
                >
                  <feather-icon icon="UserPlusIcon" />
                </b-button>
              </div>
            </b-col>
            <b-col cols="12">

              <validation-provider
                #default="validationContext"
                name="Khách thuê"
                rules="required|representor"
              >
                <tenant-table
                  v-model="itemLocal.contractTenants"
                  :rows="itemLocal.contractTenants"
                  :error="validationContext.errors[0]"
                />
              </validation-provider>
            </b-col>
          </b-row>
          <hr class="mt-4">
          <b-row>
            <b-col
              cols="12"
              class="my-1"
            >
              <div class="d-flex justify-space-between">
                <div class="d-flex justify-space-between font-small-4 font-weight-bolder text-uppercase text-primary">
                  3. Tiền thuê & Tiền cọc
                </div>
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="4">
              <validation-provider
                #default="validationContext"
                name="Tiền thuê"
                rules="required"
              >
                <number-input
                  v-if="!isTingTong"
                  v-model="itemLocal.price"
                  required
                  label="Tiền thuê"
                  :default-value="itemLocal.price"
                  :state="getValidationState(validationContext)"
                  :error="validationContext.errors[0]"
                />
                <b-form-group
                  v-else
                  label="Tiền thuê"
                >
                  <b-form-input
                    :value="Number(itemLocal.price).toLocaleString()"
                    readonly
                  />
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="4">
              <validation-provider
                #default="validationContext"
                name="paymentPeriod"
                rules="required"
              >
                <select-payment-period
                  v-model="itemLocal.paymentPeriod"
                  required
                  :default-value="itemLocal.paymentPeriod"
                  label="Chu kỳ thanh toán"
                  :state="getValidationState(validationContext)"
                  :error="validationContext.errors[0]"
                />
              </validation-provider>
            </b-col>
            <b-col md="4">
              <validation-provider
                #default="validationContext"
                name="billingDate"
                rules="required"
              >
                <select-date
                  v-model="itemLocal.billingDate"
                  required
                  :default-value="itemLocal.billingDate"
                  label="Ngày bắt đầu tính tiền"
                  :state="getValidationState(validationContext)"
                  :error="validationContext.errors[0]"
                />
              </validation-provider>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="4">
              <validation-provider
                #default="validationContext"
                name="Tiền cọc"
                rules="required"
              >
                <number-input
                  v-if="!isTingTong"
                  v-model="itemLocal.deposit"
                  required
                  label="Tiền cọc"
                  :default-value="itemLocal.deposit"
                  :state="getValidationState(validationContext)"
                  :error="validationContext.errors[0]"
                />
                <b-form-group
                  v-else
                  label="Tiền cọc"
                >
                  <b-form-input
                    :value="Number(itemLocal.deposit).toLocaleString()"
                    readonly
                  />
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              cols="4"
            >
              <b-form-group
                label="Đã đặt cọc"
              >
                <b-form-input
                  :value="reservationStake"
                  readonly
                />
              </b-form-group>
            </b-col>
            <b-col
              v-if="itemLocal.id <= 0"
              cols="4"
            >
              <b-form-group
                label="Tiền cọc phải đóng"
              >
                <b-form-input
                  :value="depositToPaid"
                  readonly
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="4">
              <validation-provider
                #default="validationContext"
                name="discount"
                rules="required"
              >
                <number-input
                  v-model="itemLocal.promotionMonth"
                  required
                  label="Số tháng giảm"
                  :default-value="itemLocal.promotionMonth"
                  :state="getValidationState(validationContext)"
                  :error="validationContext.errors[0]"
                />

              </validation-provider>
            </b-col>
            <b-col md="4">
              <validation-provider
                #default="validationContext"
                name="Số tiền giảm mỗi tháng"
                rules="required"
              >
                <number-input
                  v-model="itemLocal.promotionPricePerMonth"
                  required
                  label="Số tiền giảm mỗi tháng"
                  :default-value="itemLocal.promotionPricePerMonth"
                  :state="getValidationState(validationContext)"
                  :error="validationContext.errors[0]"
                />

              </validation-provider>
            </b-col>
          </b-row>
          <hr class="mt-4">
          <b-row>
            <b-col
              cols="12"
              class="my-1"
            >
              <div class="d-flex justify-space-between">
                <div class="d-flex justify-space-between font-small-4 font-weight-bolder text-uppercase text-primary">
                  4. Tiền phí dịch vụ
                </div>
                <b-button
                  v-b-modal.modal-list-service
                  variant="primary"
                  class="btn-icon ml-auto"
                  size="sm"
                >
                  <feather-icon icon="PlusSquareIcon" />
                </b-button>
              </div>
            </b-col>
            <b-col cols="12">

              <fee-table
                :id="itemLocal.id"
                v-model="itemLocal.contractFees"
                :rows="itemLocal.contractFees"
                :apartment="itemLocal.apartment"
                :room="itemLocal.room"
              />

            </b-col>
          </b-row>
          <b-row v-if="!itemLocal.id">
            <b-col
              cols="12"
              class="my-1"
            >
              <div class="d-flex justify-space-between">
                <div class="d-flex justify-space-between font-small-4 font-weight-bolder text-uppercase text-primary">
                  5. Đính kèm hợp đồng
                </div>
              </div>
            </b-col>
            <b-col cols="12">

              <media-view
                v-model="itemLocal.attachments"
                :label="null"
                :initial-value="itemLocal.attachments"
                :upload-imediately="true"
                @on-delete-uploaded-file="onDeleteAttachment"
              />

            </b-col>
          </b-row>
          <b-row v-if="isEnableContractAssetCheck() && itemLocal.apartment && itemLocal.room">
            <b-col
              cols="12"
              class="mt-1"
            >
              <div class="d-flex justify-space-between">
                <div class="d-flex justify-space-between font-small-4 font-weight-bolder text-uppercase text-primary">
                  6. Bàn giao tài sản
                </div>
                <b-button
                  v-if="itemLocal.id && itemLocal.id > 0"
                  v-b-modal.modal-asset-list
                  variant="primary"
                  class="btn-icon ml-auto"
                  size="sm"
                >
                  <feather-icon icon="PlusSquareIcon" />
                </b-button>
              </div>
            </b-col>
            <b-col cols="12">
              <handover-table
                v-model="itemLocal.handover"
                :contract-id="itemLocal.id"
                :apartment="itemLocal.apartment"
                :room="itemLocal.room"
                :addition-assets="additionAssets"
              />
            </b-col>
            <b-col
              v-if="isTingTong"
              cols="12"
              class="mt-1"
            >
              <div class="d-flex justify-space-between">
                <div class="d-flex justify-space-between font-small-4 font-weight-bolder text-uppercase text-primary">
                  7. Danh mục khác
                </div>
                <!-- <b-button
                  v-if="itemLocal.id && itemLocal.id > 0"
                  v-b-modal.modal-asset-list
                  variant="primary"
                  class="btn-icon ml-auto"
                  size="sm"
                >
                  <feather-icon icon="PlusSquareIcon" />
                </b-button> -->
              </div>
            </b-col>
            <b-col
              v-if="isTingTong"
              cols="12"
            >
              <other-handover-ting-tong-table
                v-model="itemLocal.otherHandover"
                :contract-id="itemLocal.id"
              />
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
      <list-tenant-modal
        :is-dormitory="!!itemLocal.bed"
        :tenants-selected="itemLocal.tenants"
        @selectTenant="onAddTenant"
      />
      <list-service-modal
        :item-local="itemLocal"
        :fees-selected="itemLocal.fees"
        :apartment="itemLocal.apartment"
        @selectService="onAddService"
      />
      <asset-list-modal
        v-if="itemLocal.apartment && itemLocal.room"
        :apartment="itemLocal.apartment"
        :room="itemLocal.room"
        :handover="itemLocal.handover"
        @on-select-assets="onSelectAssets"
      />
      <!-- <b-overlay
        no-wrap
        :show="isLoading"
      /> -->
    </b-modal>

  </div>

</template>

<script>
import {
  BModal,
  VBModal,
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BButton,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  required, representor, minValue, maxValue,
} from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import SelectApartment from "@/views/components/SelectApartment.vue";
import SelectRoom from "@/views/components/SelectRoom.vue";
import SelectBed from "@/views/components/SelectBed.vue";
import SelectDate from "@/views/components/SelectDate.vue";
import NumberInput from "@/views/components/NumberInput.vue";
import SelectPaymentPeriod from "@/views/components/SelectPaymentPeriod.vue";
import MediaView from '@/views/components/media/MediaView.vue';
import { isAllowContractPaymentDayConfig } from "@/auth/utils";
import SelectContractTemplate from '@/views/components/SelectContractTemplate.vue';
import AssetListModal from "@/views/inventory/asset/list/AssetListModal.vue";
import SelectInvoiceTemplate from '@/views/components/SelectInvoiceTemplate.vue';
import TenantTable from "./TenantTable.vue";
import FeeTable from "./FeeTable.vue";
import ListTenantModal from "../listTenantModal/ListTenantModal.vue";
import ListServiceModal from "../listServiceModal/ListServiceModal.vue";
// eslint-disable-next-line import/no-named-as-default
import useContractModal from "./useContractModal";
import HandoverTable from './HandoverTable.vue';
import OtherHandoverTingTongTable from './OtherHandoverTingTongTable.vue';

export default {
  components: {
    BModal,
    // eslint-disable-next-line vue/no-unused-components
    VBModal,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BButton,
    ValidationProvider,
    ValidationObserver,

    SelectApartment,
    SelectRoom,
    SelectBed,
    SelectDate,
    NumberInput,
    SelectPaymentPeriod,
    TenantTable,
    FeeTable,
    ListTenantModal,
    ListServiceModal,
    MediaView,
    SelectContractTemplate,
    HandoverTable,
    AssetListModal,
    SelectInvoiceTemplate,
    OtherHandoverTingTongTable,
  },
  directives: {
    "b-modal": VBModal,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    disablePropertySelect: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      required,
      representor,
      minValue,
      maxValue,
    };
  },
  computed: {
    reservationStake() {
      const { reservation } = this.itemLocal;

      if (reservation) {
        return Number(reservation.deposit).toLocaleString();
      }
      return 0;
    },
    depositToPaid() {
      const {
        room, bed, deposit, reservation,
      } = this.itemLocal;

      if (bed && reservation && deposit) {
        const totalDeposit = Number(deposit);
        const bedDeposit = Number(
          reservation.deposit,
        );
        return Number(totalDeposit - bedDeposit).toLocaleString();
      }
      if (room && room.numberActiveBeds === 0 && deposit && reservation) {
        const totalDeposit = Number(deposit);
        const roomDeposit = Number(
          reservation.deposit,
        );
        return Number(totalDeposit - roomDeposit).toLocaleString();
      }
      return Number(deposit).toLocaleString();
    },
  },
  methods: {

  },
  setup(props, { emit }) {
    const {
      refFormObserver, getValidationState, resetForm, clearForm,
    } = formValidation();

    const {
      isLoading,
      refModal,
      itemLocal,
      isSubmitting,
      isTingTong,
      additionAssets,
      onSelectAssets,
      resetItemLocal,
      resetModal,
      onAddTenant,
      onSubmit,
      onAddService,
      onDeleteAttachment,
      onStartDateChange,
      resolveColWidthIfDisableBed,
      isEnableContractAssetCheck,
    } = useContractModal(props, emit, refFormObserver);

    return {
      isLoading,
      refModal,
      itemLocal,
      isSubmitting,
      isTingTong,
      additionAssets,
      onSelectAssets,
      resetItemLocal,
      resetModal,
      onSubmit,
      onAddTenant,
      onStartDateChange,
      isAllowContractPaymentDayConfig,
      // Form Validation
      resetForm,
      clearForm,
      refFormObserver,
      getValidationState,
      onAddService,
      onDeleteAttachment,
      resolveColWidthIfDisableBed,
      isEnableContractAssetCheck,
    };
  },
};
</script>
