import { ref } from '@vue/composition-api';
import store from '@/store';
import toast from '@/utils/toast';
import meterStoreModule from '../meterStoreModule';

export default function useMeterModal(props, emit, refFormObserver) {
  const STORE_MODULE_NAME = "meter";

  // Register module
  if (!store.hasModule(STORE_MODULE_NAME)) {
    store.registerModule(STORE_MODULE_NAME, meterStoreModule);
  }

  const toastification = toast();
  const isSubmitting = ref(false);
  const refModal = ref(null);
  const refForm = ref(refFormObserver);
  const itemLocal = ref(JSON.parse(JSON.stringify(props.item)));
  const resetItemLocal = () => {
    itemLocal.value = JSON.parse(JSON.stringify(props.item));
    isSubmitting.value = false;
  };

  const resetModal = () => {
    resetItemLocal();
  };

  const onSubmit = async bvModalEvt => {
    bvModalEvt.preventDefault();

    const success = await refForm.value.validate();
    if (!success) {
      return;
    }

    const data = {
      name: itemLocal.value.name,
      description: itemLocal.value.description,
      firstIndex: itemLocal.value.firstIndex,
    };

    if (itemLocal.value.apartment && itemLocal.value.apartment.id > 0) {
      data.apartmentId = itemLocal.value.apartment.id;
    }
    if (itemLocal.value.room && itemLocal.value.room.id > 0) {
      data.roomId = itemLocal.value.room.id;
    }
    if (itemLocal.value.type) {
      data.type = itemLocal.value.type.id;
    }

    isSubmitting.value = true;

    if (itemLocal.value.id && itemLocal.value.id > 0) {
      data.id = itemLocal.value.id;
      store
        .dispatch('meter/updateMeter', data)
        .then(response => { emit('on-item-updated', response.data); })
        .then(() => {
          refModal.value.toggle('#toggle-btn');
        })
        .then(() => {
          emit('refetch-data');
        })
        .then(() => {
          toastification.showToastUpdateSuccess();
          isSubmitting.value = false;
        })
        .catch(error => {
          toastification.showToastError(error, refForm.value);
          isSubmitting.value = false;
        });
    } else {
      store
        .dispatch('meter/createMeter', data)
        .then(response => { emit('on-item-created', response.data); })
        .then(() => {
          refModal.value.toggle('#toggle-btn');
        })
        .then(() => {
          emit('refetch-data');
        })
        .then(() => {
          toastification.showToastCreateSuccess();
          isSubmitting.value = false;
        })
        .catch(error => {
          toastification.showToastError(error, refForm.value);
          isSubmitting.value = false;
        });
    }
  };

  return {
    refModal,
    refForm,
    itemLocal,
    isSubmitting,
    resetItemLocal,
    resetModal,
    onSubmit,
  };
}
