<template>
  <div>
    <b-form-group
      :label="label"
      :state="state"
    >
      <template
        v-if="label"
        v-slot:label
      >
        {{ label }}
        <span
          v-if="required"
          class="text-danger"
        >
          (*)
        </span>
      </template>
      <v-select
        id="meter"
        v-model="meter"
        class="style-chooser"
        :append-to-body="appendToBody"
        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
        label="name"
        :options="meters"
        placeholder="Chọn công tơ"
        @input="valueChanged"
        @open="onOpen"
      >
        <template
          v-if="enableAdd === true && $can('create', 'meter')"
          #list-header
        >
          <li
            class="list-header d-flex align-items-center my-50"
            @click="$bvModal.show(`modal-meter-${id}`)"
          >
            <feather-icon
              icon="PlusIcon"
              size="16"
            />
            <span class="align-middle ml-25">Thêm công tơ</span>
          </li>
        </template>
        <!-- eslint-disable-next-line vue/no-unused-vars  -->
        <template #no-options="{ search, searching, loading }">
          Không có dữ liệu
        </template></v-select>
      <small class="text-danger">{{ error }}</small>
    </b-form-group>
    <meter-modal
      v-if="enableAdd == true"
      :id="id"
      :item="{}"
      @refetch-meters="fetchData"
      @on-item-created="onItemCreated"
    />
  </div>
</template>

<script>
import { BFormGroup, VBModal } from "bootstrap-vue";
import vSelect from "vue-select";
import useJwt from "@/auth/jwt/useJwt";
import MeterModal from "../services/meter/modal/MeterModal.vue";

export default {
  components: {
    BFormGroup,
    vSelect,
    MeterModal,
    // eslint-disable-next-line vue/no-unused-components
    VBModal,
  },
  directives: {
    'b-modal': VBModal,
  },
  props: {
    label: {
      type: String,
      default: null,
    },
    state: {
      type: Boolean,
      default: null,
    },
    error: {
      type: String,
      default: null,
    },
    defaultValue: {
      type: Object,
      default: null,
    },
    id: {
      type: Number,
      default: null,
    },
    enableAdd: {
      type: Boolean,
      default: false,
    },
    appendToBody: {
      type: Boolean,
      default: false,
    },
    fee: {
      type: Object,
      default: null,
    },
    apartment: {
      type: Object,
      default: null,
    },
    room: {
      type: Object,
      default: null,
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      meter: this.defaultValue,
      meters: [],
    };
  },
  watch: {

    room(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.meter = null;
        this.valueChanged();
        if (newVal) {
          this.fetchData();
        } else {
          this.rooms = [];
        }
      }
    },
  },
  created() {
    this.fetchData();

    if (this.fee && this.fee.contractFee && this.fee.contractFee.meterId && this.fee.contractFee.meterId > 0) {
      useJwt.getMeterDetail(this.fee.contractFee.meterId).then(response => {
        this.meter = response.data;
        this.valueChanged();
      });
    }
  },
  methods: {
    valueChanged() {
      this.$emit("input", this.meter);
    },
    fetchData() {
      const filter = {};
      if (this.apartment) {
        filter.apartmentId = this.apartment.id;
      }
      if (this.room) {
        filter.roomId = this.room.id;
      }
      if (this.fee && this.fee.type) {
        filter.type = this.fee.feeCategoryId;
      }

      useJwt.getMeters({ filter }).then(res => {
        this.meters = res.data.items;
      });
    },
    onOpen() {
      if (this.meters.length === 0) {
        this.fetchData();
      }
    },
    onItemCreated(newItem) {
      this.meter = newItem;
      this.valueChanged();
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@/assets/scss/vue-select.scss';
.list-header {
  padding: $options-padding-y $options-padding-x;
  color: $secondary;

  &:hover {
    background-color: rgba($success, 0.12);
  }
}
</style>
